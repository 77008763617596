<template>
  <div class="Site PageBox">
    <van-nav-bar
        fixed
        :border="false"
        :title="$t('recharge.default[3]')"
        left-arrow
        @click-left="$router.go(-1)"
    >
      <template #right>
        <van-icon name="./skin/task01.png" size="25" @click="onClickRight"/>
      </template>
    </van-nav-bar>
    <div class="box">
      <div style="margin: 0px" class="Panel">
        <van-field readonly
                   v-model="bankInfo.coin_type"
                   @click="showPicker = true" is-link
                   :label="$t('recharge.label[0]')"
                   :placeholder="$t('recharge.placeholder[0]')"
        />
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker :cancel-button-text="$t('common[3]')"
                      show-toolbar
                      :columns="columns"
                      @cancel="showPicker = false"
                      @change="onChangeType"
          >
            <template #option="option">
              <div>
                <div :style="bankInfo.coin_type==option?'color:var(--button_bg0)':''" >{{ option }}</div>
              </div>
            </template>
          </van-picker>
        </van-popup>
        <van-field
            v-model="bankInfo.coin_address"
            :label="$t('recharge.label[1]')"
            :placeholder="$t('bindAccount.placeholder[1]')"
        >
          <div slot="right-icon">
            <van-icon name="./skin/copy.png" @click="copyCode" size="20"/>
          </div>
        </van-field>
      </div>
      <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
      <div class="QRCode">
        <img :src="bankInfo.qr_code" />
      </div>
      <!--        <div style="font-size: 16px;font-weight: bold">{{$t('recharge.label[2]')}} ({{bankInfo.coin_type}})</div>-->
      <div class="mygrid22">
        <div style="font-size: 15px;margin-bottom: 10px;font-weight: bold">{{$t('recharge.label[3]')}}({{bankInfo.coin_type}})</div>
        <div class="money">
          <van-field v-model="coinInfo.money" placeholder="0" style="width: 220px" />
        </div>
        <div style="font-size: 15px;margin-top: 15px;color: var(--button_bg0);
          margin-left: 10px;font-weight: bold">
          ≈ {{parseFloat(currPrice*coinInfo.money).toFixed(2)}} {{InitData.usdtinfo.sign}}
        </div>
      </div>

      <div class="mygrid33">
        <div style="font-size: 16px;font-weight: bold">{{$t('recharge.label[4]')}}</div>

        <div style="text-align: center;border-radius:10px;margin: 10px;border: 2px solid var(--line-color)">
          <van-uploader v-model="coinInfo.screenshots" max-count="1"
                        :after-read="afterRead"/>
        </div>
      </div>

    </div>
    <div style="text-align: center;margin-top: 20px">
      <van-button @click="submitRecharge">{{$t('recharge.default[4]')}}
      </van-button>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
import { Toast } from "vant";
import config from "@/config";

export default {
  name: "mixRecharge",
  components: {},
  props: ["walletType"],
  data() {
    return {
      showPicker: false,
      columns:[],
      rechargeinfo:[],
      bankInfo:{},
      coinPrices:{},
      currPrice:0,
      coinInfo: {
        //充币信息
        address: "",
        money: 0,
        screenshots: [],
      },
    };
  },
  computed: {
    cardList() {
      return data;
    },
  },
  watch: {},
  created() {

    this.$Model.GetCommonData((data) => {
      let currlist = data.currencylist;
      let types = data.rechargetype;
      this.rechargeinfo = types;
      for(var i=0;i<types.length;i++){
        let tt = types[i];
        let ctype = tt['coin_type'];
        this.columns.push(ctype);
        for (var k=0;k<currlist.length;k++){
          var kcurr = currlist[k];
          if (kcurr['name']==tt['bank_name']){
            this.coinPrices[ctype] = kcurr['price'];
            break;
          }
        }
      }
      this.bankInfo = types[0];
      this.currPrice = this.coinPrices[types[0]['coin_type']];
      this.coinInfo.money = 0;
    });
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {
  },
  methods: {
    onClickRight(){
      this.$router.push("/user/rechargeList");
    },
    //复制码和金额
    copyCode(i) {
      var copyBox = document.getElementById("inputBox");
      copyBox.value = this.bankInfo.coin_address;
      copyBox.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Dialog.Toast(this.$t('recharge.label[5]'));
    },
    onChangeType(obj,value){
      for(var i=0;i<this.rechargeinfo.length;i++){
        let tt = this.rechargeinfo[i];
        if (value==tt['coin_type']){
          this.bankInfo = tt;
          this.currPrice = this.coinPrices[value];
          break;
        }
      }
      this.showPicker = false;
    },
    submitRecharge() {
      if (this.coinInfo.money<=0){
        let msg = this.$t('recharge2[0]');
        this.$Dialog.Toast(msg);
        return;
      }else if (this.coinInfo.screenshots.length<=0){
        let msg = this.$t('recharge2[1]');
        this.$Dialog.Toast(msg);
        return;
      }
      this.coinInfo['coin_type'] = this.bankInfo.coin_type;
      this.coinInfo['address'] = this.bankInfo.coin_address;
      let postData = this.coinInfo;
      this.$Model.RechargeOrder(postData, (data) => {
        let msg = this.$t('taskOrder2[3]');
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break;
          case -1:
            msg = this.$t('withdraw2[6]');
            break;
          default:
            msg = data.code_dec;
            break;
        }
        this.$Dialog.Toast(msg);
        if (data.code == 1) {
          this.$router.push("/user");
        }
      });
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
            // this.submitTask();
            //显示提现弹窗
            console.log("upload success");
          } else {
            file.status = "failed";
            console.log("upload false");
            // file.message = this.$t("upload[3]");
          }
        });
      });
    },
  },
};
</script>

<style scoped>
  /* .van-nav-bar--fixed,.Site .van-nav-bar{
      background-color: #4e51bf;
  } */
  .PageBox {
    font-size: 13px;
  }

  .grid00{
    border-radius: 5px;

    padding: 5px;
  }
  .activePicker {
    color: red;
  }
  .van-cell {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
    padding: 0 0px 0 13px;
    height: 35px;
    background-color: var(--panel_color);
  }

  .PageBox .van-cell>>>.van-cell__title{
    font-size: 15px;
    font-weight: bold;
  }

  .van-cell >>> .van-field__control {
    color: var(--cell_title) !important;
    padding-left: 7px;
    height: 35px;
  }

  .PageBox .box {
    width: 95%;
    padding: 0 9px;
    font-size: 16px;
    margin-top: 15px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: transparent;
  }

  .mygrid22{
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: var(--panel_color);
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 20px;
  }

  .information {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-bottom: 26px;
  }

  .information input {
    flex: 1;
    height: 40px;
  }

  .completePanel {
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    padding: 10px;
  }

  .QRBox .upload .btn {
    padding: 10px 30px;
    border-radius: 6px;
    background-color: #4e51bf;
    color: #fff;
    text-align: center;
    margin: 41px 0 70px 0;
  }
  .van-loading {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .infoBox >>> .van-cell-group::after {
    content: none;
  }

  .van-button__text {
    white-space: nowrap;
  }

  .tool {
    display: flex;
    justify-content: center;
  }


  .withdraw img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .recharge img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .withdrawBg {
    background: #fff;
    color: #696969;
  }
  .rechargeBg {
    background: #4f51c0;
    color: #fff;
  }
  .van-cell--borderless{
    background-color: #13C465;
    color: #fff;
  }
  .btn {
    width: 95%;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: var(--button_bg);
    color: #FFFEFF;
    font-size: 18px;
    text-align: center;
    margin: 5px 10px 10px;
  }
</style>
